<template>
  <div class="form">
    <div class="logo">
      <logo />
    </div>
    <div class="title is-3-5 mb-1">Войти в систему</div>

    <div v-if="errors" class="errors is-size-7-2">
      <p
        v-for="(error, field) in errors"
        :key="field"
        class="mb-3 has-text-danger"
      >
        {{ error[0] }}
      </p>
    </div>

    <form v-on:submit.prevent="login">
      <tnd-input
        class="form__input mt-2"
        placeholder="Ваша почта"
        v-model="formValues.email"
      >
        <template #before-input>
          <user-icon />
        </template>
      </tnd-input>
      <tnd-input
        class="form__input"
        placeholder="Ваш пароль"
        type="password"
        v-model="formValues.password"
      >
        <template #before-input>
          <locker-icon />
        </template>
        <template #after-input>
          <a href="/forgot-password">Забыли?</a>
        </template>
      </tnd-input>
      <button
        @click.prevent="login"
        class="button is-normal main-button purple"
      >
        <span class="icon is-small">
          <user-icon color="#FFFFFF" />
        </span>
        <span>Войти в систему</span>
      </button>
    </form>

    <router-link to="/" class="common-link">Перейти на главную</router-link>
  </div>
</template>

<script>
import LockerIcon from "../icons/LockerIcon.vue";
import Logo from "../icons/Logo.vue";
import UserIcon from "../icons/UserIcon.vue";
import TndInput from "./TndInput.vue";

import authService from "../../services/auth.service";

export default {
  components: { Logo, TndInput, UserIcon, LockerIcon },
  data: () => ({
    formValues: {
      email: "",
      password: "",
    },
    errors: null,
  }),
  methods: {
    async login() {
      const { success, errors } = await authService.login(this.formValues);
      if (success) {
        this.$buefy.toast.open({
          message: "Вы успешно вошли в систему",
          type: "is-success",
          duration: 3000,
        });

        this.$router.push({ name: "Home" });
      } else {
        this.errors = errors;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  min-width: 50%;
  display: flex;
  flex-direction: column;

  .title {
    margin-top: 75px;
  }

  &__input:first {
    margin-top: 28px;
  }

  &__input + &__input {
    margin-top: 6px;
  }

  .button {
    margin-top: 32px;
  }

  .common-link {
    margin-top: 77px;
  }
}
</style>